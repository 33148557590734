import App from "@/App";
import "@/app/globals.css";
import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";

const IS_DEV = import.meta.env.DEV;

mixpanel.init("8e1683dc48f60c01bd331c68fadc6e27");

Sentry.init({
  dsn: "https://e14165b1c1ad4db39f5361e006a8cdd3@o4505192019329024.ingest.sentry.io/4505194547314688",
  tunnel: IS_DEV
    ? "http://localhost:3000/errors"
    : "https://api.sudocoder.io/errors",
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      // compression breaks envelope forwarding for now
      // see https://github.com/getsentry/sentry/discussions/39460
      useCompression: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
