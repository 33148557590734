import { IconBaseProps, IconType } from "react-icons";
import { AiOutlineClear } from "react-icons/ai";
import { BiHide, BiReset } from "react-icons/bi";
import { BsCheck } from "react-icons/bs";
import { CgArrowsExpandRight } from "react-icons/cg";
import { FaDiscord, FaGithub, FaTwitter } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { FiClock, FiHelpCircle, FiThumbsDown } from "react-icons/fi";
import { IoMdStopwatch } from "react-icons/io";
import {
  IoCheckmarkDoneSharp,
  IoHandRightOutline,
  IoSearch,
} from "react-icons/io5";
import { MdChecklist, MdRemove } from "react-icons/md";
import { RiCloseLine, RiFileCopyLine, RiSendPlane2Fill } from "react-icons/ri";
import { TbCode, TbDoorExit, TbSelector } from "react-icons/tb";
import { BsFillMicFill, BsFillRecordFill } from "react-icons/bs";

const wrapIcon = (Icon: IconType) => {
  const icon = (props: IconBaseProps) => <Icon {...props} />;
  return icon;
};

export const IconGoogle = wrapIcon(FcGoogle);
export const IconDiscord = wrapIcon(FaDiscord);
export const IconGitHub = wrapIcon(FaGithub);
export const IconSend = wrapIcon(RiSendPlane2Fill);
export const IconExit = wrapIcon(TbDoorExit);
export const IconHelp = wrapIcon(FiHelpCircle);
export const IconTwitter = wrapIcon(FaTwitter);
export const IconClose = wrapIcon(RiCloseLine);
export const IconReset = wrapIcon(BiReset);
export const IconReject = wrapIcon(FiThumbsDown);
export const IconSelector = wrapIcon(TbSelector);
export const IconCopy = wrapIcon(RiFileCopyLine);
export const IconClear = wrapIcon(AiOutlineClear);
export const IconHide = wrapIcon(BiHide);
export const IconStopWatch = wrapIcon(IoMdStopwatch);
export const IconCodeBlock = wrapIcon(TbCode);
export const IconExpand = wrapIcon(CgArrowsExpandRight);
export const IconSearch = wrapIcon(IoSearch);
export const IconCheck = wrapIcon(BsCheck);
export const IconInProgress = wrapIcon(FiClock);
export const IconCompleted = wrapIcon(IoCheckmarkDoneSharp);
export const IconRemove = wrapIcon(MdRemove);
export const IconHint = wrapIcon(IoHandRightOutline);
export const IconChecknotes = wrapIcon(MdChecklist);
export const IconRecord = wrapIcon(BsFillMicFill);
export const IconEndRecord = wrapIcon(BsFillRecordFill);
