import { Database } from "@/models/database.types";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";

const envRequired = (value: string | undefined): string => {
  if (!value) {
    throw new Error(`Missing required env var`);
  }
  return value;
};

// need to declare here so inlining works
const SUPABASE_URL = envRequired(import.meta.env.VITE_SUPABASE_URL);
const SUPABASE_ANON_KEY = envRequired(import.meta.env.VITE_SUPABASE_ANON_KEY);

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
  {
    auth: {
      autoRefreshToken: true,
      persistSession: true,
    },
  }
);

export const API_ENDPOINT_WS = envRequired(
  import.meta.env.VITE_API_ENDPOINT_WS
);
export const API_ENDPOINT_HTTP = envRequired(
  import.meta.env.VITE_API_ENDPOINT_HTTP
);

export const AZURE_COGNITIVE_SERVICES_SPEECH_SUBSCRIPTION_KEY = envRequired(
  import.meta.env.VITE_APP_AZURE_COGNITIVE_SERVICES_SPEECH_SUBSCRIPTION_KEY
);
export const AZURE_COGNITIVE_SERVICES_REGION = envRequired(
  import.meta.env.VITE_APP_AZURE_COGNITIVE_SERVICES_REGION
);

export const CHAT_BODY_ID = "chat-body";

export const IS_DEV = import.meta.env.NODE_ENV === "development";

export const apiClient = axios.create({
  baseURL: API_ENDPOINT_HTTP,
});
apiClient.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      const { data } = await supabase.auth.getSession();
      const token = data.session?.access_token;
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${token}`;

      // disabling for now because this messes with sessions
      // const traceId = Sentry.getCurrentHub()
      //     .getScope()
      //     ?.getSpan()
      //     ?.toTraceparent()
      // // @ts-ignore
      // config.headers['sentry-trace'] = traceId
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const DISCORD_LINK = "https://discord.gg/3vReTaWg";
export const TWITTER_LINK = "https://twitter.com/sudo_io";
