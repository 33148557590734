import { IconDiscord, IconGitHub, IconGoogle } from "@/components/Icons";
import { Spinner } from "@/components/Spinner";
import { supabase } from "@/config";
import { supabaseState } from "@/contexts/SupabaseContext";
import validator from "email-validator";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "sonner";

const buttonStyle =
  "border-neutral-20 border select-none transition-background hover:text-neutral-90 select-none transition-border transition-background hover:border-neutral-20 backdrop-blur-sm bg-neutral-10 text-neutral-60 hover:bg-neutral-10 mt-8 flex w-full items-center justify-center space-x-3 rounded-md p-2 lg:mt-0";

const Login = observer(() => {
  const { supabaseSession: session } = supabaseState;

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { from } = location.state || { from: "" };

  const redirectTo = useMemo(() => {
    return `${window.location.origin}${from || ""}`;
  }, [from, window.location.origin]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validator.validate(email)) {
      toast.error("Invalid email! Make sure you enter a valid email address.");
      return;
    }

    setLoading(true);
    const signInPromise = async () => {
      try {
        await supabase.auth.signInWithOtp({
          email,
          options: {
            emailRedirectTo: redirectTo,
          },
        });
      } catch (error) {
        toast.error("Couldn't send login link");
      } finally {
        setLoading(false);
      }
    };

    await toast.promise(signInPromise(), {
      loading: "Sending login link...",
      success: "Email sent! Check your spam folder if you don't see it.",
      error: "Error, try again!",
    });
  };

  if (session) {
    return <Navigate to="/practice" />;
  }
  return (
    <div className="flex h-screen w-screen items-center justify-center p-4 md:p-8 lg:p-16">
      <div className="z-10 w-full max-w-sm space-y-2 rounded-lg p-4">
        <h1 className="mb-8 select-none text-center text-2xl font-semibold">
          Sign in to Sudocoder
        </h1>
        <button
          className={buttonStyle}
          aria-live="polite"
          onClick={async () => {
            const oauth = await supabase.auth.signInWithOAuth({
              provider: "google",
              options: {
                redirectTo,
                skipBrowserRedirect: true,
              },
            });
            if (oauth.data.url) {
              window.location.href = oauth.data.url;
            }
          }}
        >
          <IconGoogle className="h-6 w-6" />
          <span className="font-medium">Continue with Google</span>
        </button>

        <button
          className={buttonStyle}
          aria-live="polite"
          onClick={async () => {
            const oauth = await supabase.auth.signInWithOAuth({
              provider: "github",
              options: {
                redirectTo,
                skipBrowserRedirect: true,
              },
            });
            if (oauth.data.url) {
              window.location.href = oauth.data.url;
            }
          }}
        >
          <IconGitHub className="h-6 w-6" />
          <span className="font-medium">Continue with GitHub</span>
        </button>

        <button
          className={buttonStyle}
          aria-live="polite"
          onClick={async () => {
            const oauth = await supabase.auth.signInWithOAuth({
              provider: "discord",
              options: {
                redirectTo,
                skipBrowserRedirect: true,
              },
            });
            if (oauth.data.url) {
              window.location.href = oauth.data.url;
            }
          }}
        >
          <IconDiscord className="h-6 w-6" />
          <span className="font-medium">Continue with Discord</span>
        </button>

        <div className="flex items-center justify-center">
          <hr className="grow border-neutral-20"></hr>
          <span className="m-4 select-none text-neutral-30">or</span>
          <hr className="grow border-neutral-20"></hr>
        </div>

        <form className="flex flex-col space-y-2" onSubmit={handleLogin}>
          <input
            className={
              "rounded-md border border-neutral-20 bg-neutral-10 px-3 py-1.5 text-neutral-90 outline-none backdrop-blur-sm focus:border-neutral-20 disabled:cursor-not-allowed"
            }
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <button
            className={`flex select-none justify-center rounded-md border border-neutral-20 bg-neutral-10 p-2 font-semibold text-neutral-60 backdrop-blur-sm transition-[background,border] hover:text-neutral-90 enabled:hover:border-neutral-20 enabled:hover:bg-neutral-10 disabled:cursor-not-allowed`}
            aria-live="polite"
          >
            {!loading ? "Send magic link" : <Spinner />}
          </button>
        </form>
      </div>
    </div>
  );
});

export default Login;
