// eslint-disable-next-line mobx/missing-observer
export const Spinner = () => {
  return (
    <div className="block h-4 w-4 animate-spin-fast rounded-full border-[0.125rem] border-neutral-20 border-l-[rgb(25,25,25)] p-0.5"></div>
  );
};

// eslint-disable-next-line mobx/missing-observer
export const ActionSpinner = () => {
  return (
    <div className="block h-4 w-4 animate-spin-fast rounded-full border-[0.125rem] border-primary-30 border-l-primary-50 p-0.5"></div>
  );
};
