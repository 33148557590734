import Login from "@/app/Login";
import SupabaseProvider from "@/contexts/SupabaseContext";
import { observer } from "mobx-react-lite";
import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Toaster } from "sonner";

const Practice = lazy(() => import("@/app/Practice"));

const App = observer(function App() {
  return (
    <SupabaseProvider>
      <Toaster
        toastOptions={{
          className:
            "border border-neutral-20 bg-neutral-5 shadow text-neutral-95",
        }}
        position="bottom-left"
        richColors
        expand={true}
      />
      <Routes>
        <Route path="/practice/:problemId?" element={<Practice />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/practice" />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </SupabaseProvider>
  );
});

export default App;
