import { supabase } from "@/config";
import * as Sentry from "@sentry/react";
import { Session } from "@supabase/supabase-js";
import mixpanel from "mixpanel-browser";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext, useEffect } from "react";

class SupabaseState {
  supabaseSession: Session | null = null;
  loaded = false;

  constructor() {
    makeAutoObservable(this);
  }
}

export const supabaseState = new SupabaseState();
export const SupabaseContext = createContext<SupabaseState>(supabaseState);

// eslint-disable-next-line mobx/missing-observer
const SupabaseProvider = function SupabaseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      runInAction(() => {
        supabaseState.supabaseSession = session;

        if (session) {
          mixpanel.identify(session.user.id);
          Sentry.setUser({
            ip_address: "{{auto}}",
            username: session.user.user_metadata.username,
            id: session.user.id,
            email: session.user.email,
          });
        }

        supabaseState.loaded = true;
      });
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      runInAction(() => {
        supabaseState.supabaseSession = session;
      });
    });
  }, []);

  return (
    <SupabaseContext.Provider value={supabaseState}>
      {children}
    </SupabaseContext.Provider>
  );
};

export default SupabaseProvider;

export const useSupabaseContext = () => {
  return useContext(SupabaseContext);
};
